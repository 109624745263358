<!--
 * @Description: 
 * @Author: fely
 * @Date: 2021-02-05 14:07:00
 * @LastEditTime: 2021-03-03 09:47:28
 * @LastEditors: fely
-->
<template>
  <div class="pos-list-page">
    <div class="header">
      <top-header :white="true"></top-header>
    </div>
    <div class="content">
      <div class="wrapper">
        <div class="title-box">
          <p>热门职位</p>
          <div class="post-btn" @click="handlePost">发布职位</div>
        </div>
        <div class="pos-list" v-loading="loading">
          <div class="postion" v-for="item in list" :key="item.postid" @click="goPosDetail(item.postid)">
            <div class="pos-info">
              <div class="pos-name">
                <span class="name">{{item.postname}}</span>
                <span class="salary">{{item.lowsalarie}}-{{item.highsalarie}}K</span>
              </div>
              <div class="addr">
                <span>{{item.address.split(',')[1]}} · {{item.address.split(',')[2]}}</span>
                <span class="left">{{item.workyear=='不限'?'经验不限':item.workyear}}</span>
                <span class="left">{{item.edutype=='不限'?'学历不限':item.edutype}}</span>
              </div>
            </div>
            <div class="seprate"></div>
            <div class="com-info">
              <div class="com-img">
                <el-image fit="cover" :src="item.jbxxLogo">
                  <div slot="placeholder" class="image-slot">
                    <img src="~@/assets/images/comImg.png">
                  </div>
                  <div slot="error" class="image-slot">
                    <img src="~@/assets/images/comImg.png">
                  </div>
                </el-image>
              </div>
              <div class="com-text">
                <p class="com-name">{{item.jbxxRealname}}</p>
                <p class="com-other">
                  <span>{{item.financingstage}}</span>
                  <span class="left">{{item.companysize}}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="pager">
          <div class="pre" v-show="queryParams.pagenum>1" @click="handlePre">上一页</div>
          <div class="next" v-show="!isLast" @click="handleNext">下一页</div>
        </div>
      </div>
      <div class="footer" style="margin-top: 30px">
        <p>©天津市亿慧网络科技有限公司 版权所有 <a class="beian"  target="_black" href="http://beian.miit.gov.cn">津ICP备20006513号</a></p>
        <div style="margin-left: 20px;">
          <a target="_blank" href="http://www.beian.gov.cn/" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
            <img src="~@/assets/images/police.png" style="float:left;" />
            <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">津公网安备 12011102001059号</p>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from './topHeader'
import { getPosRec } from '@/api/posList.js'
import { getTocken } from '@/utils/auth'
export default {
  name: '',
  components: {
    TopHeader
  },
  data() {
    return {
      list: [],
      queryParams: {
        pagenum: 1,
        pagesize: 12
      },
      isLast: false,
      loading:false
    }
  },
  created() {
    this.init()
  },
  methods: {
    init(){
      this.loading = true
      getPosRec(this.queryParams).then(res => {
        this.list = res.data
        this.loading = false
        if(this.list.length<12){
          this.isLast = true
        }
      })
    },
    handleNext(){
      this.queryParams.pagenum ++
      this.init()
    },
    handlePre(){
      this.queryParams.pagenum --
      this.isLast = false
      this.init()
    },
    handlePost(){
      if(getTocken()){
        this.$router.push('/post')
      }else{
        this.$bus.emit("showLogin")
      }
      
      
    },
    goPosDetail(id){
      const { href } = this.$router.resolve({
        path: '/posDetail',
        query: {
          id: id
        }
      });
      window.open(href, '_blank');
    }
  },
}
</script>

<style scoped lang="less">
.pos-list-page{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .header{
    position: relative;
    height: 60px;
    width: 100%;
    background-color: #fff;
  }
  .content{
    flex: 1;
    background-color: #F0F2F6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    .wrapper{
      width: 990px;
      .title-box{
        padding: 20px 0;
        padding-right: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        color: #333;
        .post-btn{
          width: 88px;
          height: 36px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #4e90ff;
          border-radius: 2px;
          color: #fff;
          font-size: 14px;
          cursor: pointer;
        }
      }
      .pos-list{
        display: flex;
        flex-wrap: wrap;
        .postion{
          cursor: pointer;
          width: 320px;
          margin-right: 10px;
          margin-bottom: 10px;
          background-color: #fff;
          padding: 20px;
          box-sizing: border-box;
          .left{
            margin-left: 12px;
          }
          .pos-info{
            .pos-name{
              text-align: left;
              display: flex;
              justify-content: space-between;
              align-items: center;
              .name{
                font-size: 14px;
                font-weight: 600;
                width: 150px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
              .salary{
                font-size: 13px;
                color: #FF5A5F;
              }
            }
            .addr{
              display: flex;
              margin-top: 12px;
              color: #666;
              font-size: 12px;
            }
          }
          .seprate{
            margin: 12px 0;
            height: 1px;
            width: 100%;
            background-color: #f5f5f5;
          }
          .com-info{
            display: flex;
            .com-img{
              width: 36px;
              height: 36px;
              border-radius: 4px;
              overflow: hidden;
              .image-slot{
                width: 100%;
                height: 100%;
              }
            }
            .com-text{
              margin-left: 12px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              .com-name{
                font-size: 12px;
                color: #333;
              }
              .com-other{
                color: #666;
                font-size: 10px;
              }
            }
          }
        }
      }
      .pager{
        margin-top: 16px;
        width: 100%;
        height: 68px;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        .pre,.next{
          cursor: pointer;
          height: 32px;
          width: 80px;
          background-color: #f7f7f7;
          color: #828282;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
        }
        .next{
          margin-left: 10px;
        }
      }
    }
  }
}
.footer{
  height: 27px;
  width: 100%;
  display: -webkit-box; /* 老版本语法: Safari, iOS, Android browser,         older WebKit browsers. */
  display: -moz-box; /* 老版本语法: Firefox (buggy) */
  display: -ms-flexbox; /* 混合版本语法: IE 10 */
  display: -webkit-flex; /* 新版本语法: Chrome 21+ */
  display: flex;
  justify-content: center;
  padding-bottom: 27px;
  font-size: 13px;
  box-sizing: border-box;
}
a.beian,.footer{
  color: #939295;
}
a.beian:hover{
  text-decoration:underline;
}
</style>
